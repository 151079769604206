<template>
  <div></div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import { mapGetters, mapMutations } from 'vuex'
import parcelle from '@/mixins/parcelle'
import batiment from '@/mixins/batiment'
export default {
  mixins: [Mapbox, parcelle, batiment],
  computed: {
    ...mapGetters({
      address: 'address/address',
      features_autour: 'parcelle/features_autour',
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      is_parcelle_changing: 'parcelle/is_parcelle_changing',
      is_parcelle_aggregating: 'parcelle/is_parcelle_aggregating',
      map: 'map/map',
      map_style_loaded: 'map/map_style_loaded',
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  data() {
    return {
      max_parcelles_aggregate: 20,
      id_set_interval_opacity_layer: null,
      id_time_out: null,
      old_current_parcelle: null,
    }
  },

  methods: {
    ...mapMutations({
      toggleGuestModal: 'auth/TOGGLE_GUEST_MODAL',
    }),
    propertieNumExist(feature) {
      return this.isExist(this.getNestedObject(feature, 'properties', 'numero'))
    },
    selectOrUnselectParcelle(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: [this.layer_background_parcelle_autour],
      })
      if (this.propertieNumExist(features[0])) {
        var parcelle_selected = this.parcelles_autour[features[0].id]

        let reference_cadastrale_selected = parcelle_selected.properties.id

        let ref_layer_parcelle_selected =
          this.source_layers_all_parcelles_selected[1] +
          '-' +
          reference_cadastrale_selected

        if (this.layerExist(ref_layer_parcelle_selected)) {
          this.setInfosParcelles(
            this.unSelectParcelle(
              parcelle_selected,
              reference_cadastrale_selected,
            ),
            true,
          )
        } else if (
          this.own_parcelles.length < this.max_parcelles_aggregate &&
          !this.layerExist(ref_layer_parcelle_selected)
        ) {
          this.setInfosParcelles(
            this.selectParcelle(
              reference_cadastrale_selected,
              parcelle_selected,
            ),
          )
        } else if (this.own_parcelles.length >= this.max_parcelles_aggregate) {
          this.toast(
            'Validation',
            `vous avez le droit de sélectionner ${this.max_parcelles_aggregate} parcelles  maximum ! `,
            'warning',
          )
        }
        this.filterParcellesAutour()
      }
    },
    selectParcelle(reference_cadastrale_selected, parcelle_selected) {
      let options = this.copyObject(this.options_polygone)
      options.color = this.green_parcelle_selected
      this.addPolygonsToMap(
        parcelle_selected,
        this.concatSourceAndLayersWithRef(
          ...this.source_layers_all_parcelles_selected,
          reference_cadastrale_selected,
        ),
        options,
      )
      let own_new_parcelles = [...this.own_parcelles, parcelle_selected]
      let new_current_parcelle =
        this.getParcellePrincipalThroughCenterOfAllParcelles(own_new_parcelles)

      let new_parcelles_voisines = own_new_parcelles.filter(
        (parcelle) =>
          parcelle.properties.id !== new_current_parcelle.properties.id,
      )
      return {
        current_parcelle: new_current_parcelle,
        parcelles_voisines: new_parcelles_voisines,
      }
    },

    unSelectParcelle(parcelle_selected, reference_cadastrale_selected) {
      this.removeSourceAndLayers(
        ...this.concatSourceAndLayersWithRef(
          ...this.source_layers_all_parcelles_selected,
          reference_cadastrale_selected,
        ),
      )
      let own_new_parcelles = this.own_parcelles.filter(
        (parcelle) =>
          parcelle.properties.id !== parcelle_selected.properties.id,
      )
      let new_current_parcelle =
        this.getParcellePrincipalThroughCenterOfAllParcelles(own_new_parcelles)

      this.old_current_parcelle = new_current_parcelle
        ? new_current_parcelle
        : this.current_parcelle

      let new_parcelles_voisines = new_current_parcelle
        ? this.own_parcelles.filter(
            (parcelle) =>
              parcelle.properties.id !== parcelle_selected.properties.id &&
              parcelle.properties.id !== new_current_parcelle.properties.id,
          )
        : []

      return {
        current_parcelle: new_current_parcelle,
        parcelles_voisines: new_parcelles_voisines,
      }
    },

    disableAnimateLayers() {
      this.id_set_interval_opacity_layer = this.animateLayers(
        false,
        this.getLayersParcellesSelected(this.own_parcelles),
        this.id_set_interval_opacity_layer,
      )
    },
    setInfosParcelles(parcelles, is_parcelle_removed = false) {
      this.$store.commit(
        'parcelle/CURRENT_PARCELLE',
        parcelles.current_parcelle,
      )
      this.$store.commit(
        'parcelle/PARCELLES_VOISINES',
        parcelles.parcelles_voisines,
      )
      this.removeSourceAndLayers(...this.source_layers_all_parcelles_selected)
      if (this.current_parcelle && this.isLoggedIn) {
        this.$emit('updateAddress', is_parcelle_removed)
         this.getAltimetries(parcelles);
      }else if(this.current_parcelle){
        this.updateAddress(parcelles)
        this.getAltimetries(parcelles); 
      }
      clearTimeout(this.id_time_out)
      this.disableAnimateLayers()
      this.id_set_interval_opacity_layer = this.animateLayers(
        true,
        this.getLayersParcellesSelected(this.own_parcelles),
      )
      this.id_time_out = setTimeout(this.disableAnimateLayers, 4000)
    },
    updateAddress(parcelles){
       try {
        let current_parcelle =
        this.getParcellePrincipalThroughCenterOfAllParcelles([parcelles.current_parcelle,...parcelles.parcelles_voisines])
        let coords = this.$turf.pointOnFeature(current_parcelle).geometry.coordinates.map((coord ) => coord.toString())
        this.$http.get('grand-publics/gouv-url/reverse/no-auth', {
          params: {
              lon: coords[0],
              lat: coords[1],
          },
      }).then((response) => {
          let adresse = response.data.features[0];
           this.address.information.properties = adresse.properties
           this.address.information.geometry.coordinates = adresse.geometry.coordinates.map((coord ) => coord.toString())
      })
      } catch (error) {
        console.error(error);
      }
    },
    getAltimetries(parcelles){
      try {        
        this.$store.dispatch('parcelle/getAltimetries',this.$turf.pointOnFeature(parcelles.current_parcelle).geometry.coordinates)
        }
        catch(err) {
          console.log(err)
        }
    }
  },
  watch: {
    own_parcelles: {
      handler() {
        let own_parcelles = [
          this.current_parcelle,
          ...this.parcelles_voisines,
        ].filter((parcelle) => parcelle)

        this.$emit(
          'addMakerslengthsWall',
          own_parcelles.length ? this.$turf.union(...own_parcelles) : null,
        )
        this.$emit('resetDetailsParcelles')
      },
    },
    map_style_loaded(is_loaded) {
      if (is_loaded) {
        this.map.on('click', this.selectOrUnselectParcelle)
      }
    },
  },
  beforeDestroy() {
    if (!this.current_parcelle) {
      this.$store.commit('parcelle/CURRENT_PARCELLE', this.old_current_parcelle)
      this.$store.commit(
        'parcelle/CURRENT_BATIMENTS',
        this.getAllBatimentsWithUniqueId(
          this.getFeaturesExist(this.features_autour, this.own_parcelles),
          this.own_parcelles,
        ),
      )
    }
    if (this.map) {
      this.disableAnimateLayers()
      this.map.off('click', this.selectOrUnselectParcelle)
    }
  },
}
</script>
